import { WebApiService } from '../../plugins/webApiService'
import { Events } from '../../plugins/events'
import EventToAsyncDecorator from '../../plugins/eventToAsyncDecorator'
import GetProjectLogGroupsRequest from '../../LoggerDictionary/IServices/Models/Request/GetProjectLogGroupsRequest';
import GetEndpointsRequest from '../../LoggerDictionary/IServices/Models/Request/GetEndpointsRequest';
import GetEndpointRequest from '../../LoggerDictionary/IServices/Models/Request/GetEndpointRequest';
import WsResponseModelT from '../../SchemaBuilderServer/Models/WsResponseModelT';
import GetProjectLogGroupsResponse from '../../LoggerDictionary/IServices/Models/Response/GetProjectLogGroupsResponse';
import GetEndpointsResponse from '../../LoggerDictionary/IServices/Models/Response/GetEndpointsResponse';
import Endpoint from '../../LoggerMicroService/DataModels/Endpoints/Endpoint';

export default class LoggerDictionaryService {
	webApiService: WebApiService;
	GetProjectLogGroupsEvent = new Events<WsResponseModelT<GetProjectLogGroupsResponse>>();
	GetEndpointsEvent = new Events<WsResponseModelT<GetEndpointsResponse>>();
	GetEndpointEvent = new Events<WsResponseModelT<Endpoint>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('LoggerDictionaryService', 'GetProjectLogGroups', (res: WsResponseModelT<GetProjectLogGroupsResponse>) => {
			self.GetProjectLogGroupsEvent.trigger(res);
		});
		webApiService.on('LoggerDictionaryService', 'GetEndpoints', (res: WsResponseModelT<GetEndpointsResponse>) => {
			self.GetEndpointsEvent.trigger(res);
		});
		webApiService.on('LoggerDictionaryService', 'GetEndpoint', (res: WsResponseModelT<Endpoint>) => {
			self.GetEndpointEvent.trigger(res);
		});
	}
	GetProjectLogGroups(request: GetProjectLogGroupsRequest = null) { 	
        this.webApiService.send('LoggerDictionaryService', 'GetProjectLogGroups' , request);
    }
	GetEndpoints(request: GetEndpointsRequest = null) { 	
        this.webApiService.send('LoggerDictionaryService', 'GetEndpoints' , request);
    }
	GetEndpoint(request: GetEndpointRequest = null) { 	
        this.webApiService.send('LoggerDictionaryService', 'GetEndpoint' , request);
    }
	 // async methods
	async getProjectLogGroupsAsync(request: GetProjectLogGroupsRequest = null): Promise<GetProjectLogGroupsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetProjectLogGroups.bind(this), this.GetProjectLogGroupsEvent)(request);
	}
	async getEndpointsAsync(request: GetEndpointsRequest = null): Promise<GetEndpointsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetEndpoints.bind(this), this.GetEndpointsEvent)(request);
	}
	async getEndpointAsync(request: GetEndpointRequest = null): Promise<Endpoint> {
		return EventToAsyncDecorator.getInstance().bind(this.GetEndpoint.bind(this), this.GetEndpointEvent)(request);
	}
}