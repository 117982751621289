<template>
  <!-- <ui-notification></ui-notification> -->
  <!-- <ui-dialog></ui-dialog> -->
  <div v-if="logined" class="layout">
    <layout-sidebar v-model:hideMenu="hideMenu"/>
    <div class="container-custom">
      <layout-header v-model:hideMenu="hideMenu"/>
      <div class="content">
        <router-view/>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import {Options, Vue} from "vue-class-component";
import LayoutHeader from "@layouts/layout-header.vue";
import LayoutSidebar from "@layouts/layout-sidebar.vue";
import UiNotification from "@/plugins/notification/ui-notification.vue";
import {reactive} from "vue";

@Options({
  components: {
    LayoutHeader,
    LayoutSidebar,
    UiNotification,
  },

  provide() {
    return {
      user: this.user,
      settings: this.settings,
    };
  },
})
export default class Layout extends Vue {
  MyDependency = {
    foo: "bar",
  };
  settings = reactive({theme: "light"});
  user = "John Doe";
  logined = true;
  hideMenu = false;

  mounted() {
  }

  onClickBurger(value: boolean) {
    console.log(`onClickBurger`);
    this.hideMenu = value;
  }
}
</script>
<style lang="less">
body {
  @font-face {
    font-family: ProximaNova;
    src: url("~@assets/styles/proxima-nova/fonts/ProximaNova-Regular.eot?dsqk3s#iefix") format("embedded-opentype"),
    url("~@assets/styles/proxima-nova/fonts/ProximaNova-Regular.ttf?dsqk3s") format("truetype"),
    url("~@assets/styles/proxima-nova/fonts/ProximaNova-Regular.woff?dsqk3s") format("woff");
    font-weight: 100;
    font-style: normal;
  }
  margin: 0;
  font-family: ProximaNova;
  font-style: normal;
  font-weight: normal;
  // overflow: hidden;
}

.layout {
  display: flex;
  min-height: 100vh;

  .container-custom {
    flex-grow: 1;
    background: #ececec;
    display: flex;
    flex-direction: column;

    .content {
      // background: #ffffff;
      margin: 20px 30px;
      flex-grow: 1;
      overflow-x: hidden;
    }
  }
}

.tirscript-list-view-item.selected {
  background-color: grey !important;
}

.tirscript-date-picker .input-group input {
  border: 1px solid #d2d2d2 !important;
  background: white !important;
  color: black !important;
}

.tirscript-date-picker .input-group input.focus {
  color: black;
}

.tirscript-date-picker .input-group input.focus {
  border: 1px solid #d2d2d2 !important;
  background: white !important;
}

.date-time {
  background: white !important;
  color: black !important;

  .navigation {
    color: black !important;
  }
}

.days .items .day.no-active {
  color: #b8c3e0 !important;
}

.days .items .day {
  color: #465675 !important;
}

.days .items .day.no-active {
  color: #b8c3e0 !important;
}

.years .items .year {
  color: #465675 !important;
}

.years .items .year.no-active {
  color: #b8c3e0 !important;
}

.months .items .month {
  color: #465675 !important;
}

.tirscript-table .tirscript-table-body-item {
  color:  #4d3549 !important;
}
</style>
