<template>
  <div class="filter">
    <div class="source-data-interval-button-wrapper">
      <div v-show="slectSource" class="source-wrapper">
        <select-source-component
            @onChangeSource="changeSource"
        ></select-source-component>
      </div>
      <div v-show="selectData" class="date-wrapper">
        <tirscript3-date-picker
            v-model:modelValue="innerFilter.DateFrom"
            changeDateManual="true"
            dateFormat="DD.MM.YYYY HH:mm:ss"
            initialView="days"
            maximumView="years"
            minimumView="minutes"
        ></tirscript3-date-picker>
        <!--        :modelValue="new Date(innerFilter.DateFrom.getTime())"-->
        <!--        @update:modelValue="innerFilter.DateFrom=$event"-->
        <tirscript3-date-picker
            v-model:modelValue="innerFilter.DateTo"
            changeDateManual="true"
            dateFormat="DD.MM.YYYY HH:mm:ss"

            initialView="days"
            maximumView="years"
            minimumView="minutes"
        ></tirscript3-date-picker>
        <!--        :modelValue="new Date(innerFilter.DateTo.getTime())"-->
        <!--        @update:modelValue="innerFilter.DateTo=$event"-->
      </div>

      <tirscript3-checkbox v-show="liveReload" @change="onLiveReload">Live Reload</tirscript3-checkbox>

      <div v-show="selectInterval" class="interval-wrapper">
        <select-interval-component
            @onChangeInterval="changeInterval"
        ></select-interval-component>
      </div>

      <div v-show="selectButton" class="button-wrapper">
        <tirscript3-button @onClick="filterData">Показать</tirscript3-button>
      </div>
    </div>

    <div v-show="selectLogsFilter" class="logs-wrapper">
      <logs-view-filter
          :loggerName="loggerName"
          :logsLevel="logsLevel"
          :logsType="logsType"
          :methods="methods"
          :requestId="requestId"
          :shortMessage="shortMessage"
          :url="url"
          @onFilter="filterLogs"
      ></logs-view-filter>
    </div>
  </div>
</template>
<script lang="ts">
import {Options, Prop, Vue, Watch} from "vue-property-decorator";
import FilterModel from "@/views/page/components/filter-model";
import LogsViewFilter from "@/views/components/filter-component/logs-view-filter.vue";
import ViewFilter from "@/views/components/filter-component/logs-view-filter.vue";

@Options({
  name: "FilterComponent",
  components: {
    LogsViewFilter,
    ViewFilter,
  },
})
export default class FilterComponent extends Vue {
  @Prop({default: true}) slectSource: boolean;
  @Prop({default: true}) selectData: boolean;
  @Prop({default: true}) selectInterval: boolean;
  @Prop({default: true}) selectLogsFilter: boolean;
  @Prop({default: true}) selectViewFilter: boolean;
  @Prop({default: true}) selectButton: boolean;

  @Prop({default: true}) methods: boolean;
  @Prop({default: true}) logsType: boolean;
  @Prop({default: true}) logsLevel: boolean;
  @Prop({default: true}) url: boolean;
  @Prop({default: true}) requestId: boolean;
  @Prop({default: true}) loggerName: boolean;
  @Prop({default: true}) shortMessage: boolean;
  @Prop({default: false}) liveReload: boolean;
  @Prop({}) filter: FilterModel;

  innerFilter: FilterModel = null;
  isLiveReload: boolean = false;

  created() {
    this.innerFilter = this.filter;
  }


  // @Watch("filter", {deep: true})
  // onFilter(newFilter: FilterModel, oldFilter) {
  //   this.innerFilter = newFilter;
  //   this.$emit("update:filter", this.innerFilter);
  // }

  changeSource(value: number) {
    this.innerFilter.ProjectLogGroupId = value;
  }

  changeInterval(value: number) {
    this.innerFilter.interval = value;
  }

  filterData() {
    this.$emit("filterData");
  }

  filterLogs(value: FilterModel) {
    this.innerFilter.EndpointId = value.EndpointId;
    this.innerFilter.LogType = value.LogType;
    this.innerFilter.LogLevel = value.LogLevel;
    this.innerFilter.HardwareLogsType = value.HardwareLogsType;
    this.innerFilter.Url = value.Url;
    this.innerFilter.SessionToken = value.SessionToken;
    this.innerFilter.Ip = value.Ip;
    this.innerFilter.UserId = value.UserId;
    this.innerFilter.ConnectionId = value.ConnectionId;
    this.innerFilter.RequestId = value.RequestId;
    this.innerFilter.LoggerName = value.LoggerName;
    this.innerFilter.ShortMessage = value.ShortMessage;
  }

  onLiveReload () {
    this.isLiveReload = !this.isLiveReload
    this.$emit("onLiveReload", this.isLiveReload)
  }

}
</script>

<style lang="less">
.filter {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  width: 100%;

  .source-data-interval-button-wrapper {
    display: flex;
    justify-content: space-between;

    .date-wrapper {
      display: flex;
    }
  }

  .logs-wrapper {
    margin-top: 20px;
  }

  .view-filter-wrapper {
    margin-top: 20px;
  }
}
</style>