<template>
  <div
      class="tirscript-table-header-item"
      v-bind:class="{ 'cursor-pointer': !!sortName }"
      v-bind:style="styleCustom"
      @click="sortExecute"
  >
    <div>
      <slot></slot>
    </div>
    <div v-show="sortName" :class="{'not-active-sort': isNotActiveSort}" class="ml-2">
      <div :class="[{ active: sortType == 1 }, iconAsc]"></div>
      <div style="height: 5px"></div>
      <div :class="[{ active: sortType == 0 }, iconDesk]"></div>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Prop, Vue, Watch} from "vue-property-decorator";

@Options({
  name: "TableHeaderItemComponent"
})
export default class TableHeaderItemComponent extends Vue {
  @Prop({default: null, type: String}) styleCustom: Object = {};
  @Prop({default: null, type: String}) sortName: string = "";
  @Prop({default: "asc", type: String}) defaultSort: string = "";
  @Prop({default: "asc", type: String}) iconAsc: string = "";
  @Prop({default: "desc", type: String}) iconDesk: string = "";
  @Prop({default: "false", type: Boolean}) isNotActiveSort = false;

  sortType: number = 0

  mounted() {
    this.changeSortType()
  }

  changeSortType() {
    if (this.defaultSort == "asc")
      this.sortType = 1;
    else if (this.defaultSort == "desc")
      this.sortType = 0;
    else if (this.defaultSort == null)
      this.sortType = 1
  }

  sortExecute() {
    if (!this.sortName) return;
    if (this.sortType == 1) this.sortType = 0;
    else if (this.sortType == 0) this.sortType = 1;
    this.$emit("onSort", this.sortName, this.sortType);
  }

}
</script>

<style lang="less" scooped>
@TirscriptTableHeaderItem: #5197e9;
.Tirscript-table-header-item {
  .icon-cartex-admin-sort-up,
  .icon-cartex-admin-sort-down {
    font-size: 6px;
    cursor: pointer;

    &.active {
      &::before {
        color: @TirscriptTableHeaderItem;
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.asc {
  border: 3px solid transparent;
  border-bottom: 7px solid lightblue;
}

.desc {
  border: 3px solid transparent;
  border-top: 7px solid lightblue;
}

.active {
  &.asc {
    border: 3px solid transparent;
    border-bottom: 7px solid #556a71;
  }

  &.desc {
    border: 3px solid transparent;
    border-top: 7px solid #556a71;
  }
}

.not-active-sort {
  .asc {
    border-bottom: 7px solid #cccccc;
  }

  .desc {
    border-top: 7px solid #cccccc;
  }
}
</style>