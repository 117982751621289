<template>
  <tirscript3-dropdown
    placeholder="Источник"
    :items="sourceLogsList"
    @change="onChangeSource"
    v-model="filter.ProjectLogGroupId"
  ></tirscript3-dropdown>
</template>
<script lang="ts">
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import ListViewItem from "tirscript3-list-view/src/ListViewItem";
import DefaultModel from "tirscript3-component-models/src/defaultModel";
import FilterModel from "@/views/page/components/filter-model";

@Options({
  name: "SelectSource",
})
export default class SelectSource extends Vue {
  sourceLogsList: ListViewItem<DefaultModel>[] = [];
  filter: FilterModel = new FilterModel();

  created() {
    this.sourceLogsList = [
      new ListViewItem(new DefaultModel(null, "Все источники"), true, false),
    ];
    this.filter.ProjectLogGroupId = (
      this.sourceLogsList.filter((el) => el.Selected === true)[0]
        .Value as DefaultModel
    ).id;
  }

  async mounted() {
    await this.getSourceLogsList();
  }

  async getSourceLogsList() {
    let res = [];
    try {
      res = await this.$api.ProjectLogGroupService.getProjectLogGroupsAsync();
      res.forEach((item) => {
        this.sourceLogsList.push(
          new ListViewItem(
            new DefaultModel(item.Id, `${item.ProjectName}/${item.ServerName}`),
            false,
            false
          )
        );
      });
    } catch (error) {
      console.log("error", error);
    }
  }

  onChangeSource(elements: ListViewItem<DefaultModel>[]) {
    let id = (elements[0].Value as DefaultModel).id;
    this.filter.ProjectLogGroupId = id;
    this.$emit("onChangeSource", this.filter.ProjectLogGroupId);
  }
}
</script>

<style lang="less">
.toolbar {
  display: flex;
  justify-content: space-between;
  .tirscript-dropdown {
    flex: 0 1 45%;
  }
  .date-wrapper {
    display: flex;
    flex: 0 1 45%;
    .tirscript-date-picker {
      flex: 0 1 50%;
    }
  }
  .tirscript-button {
    flex: 0 0 8%;
  }
}
.table-icon {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>