import { App } from "vue";
import { Router } from "vue-router";
// import Vuex, { Store } from 'vuex'
// import ApiDataSource from "./api/dataSource/api/apiDataSource";
import Api from "./dataSource/api/apiDataSource";
// import { LOGIN, SPECIALISTS, SESSION } from "@/router/routerNames";
// import { StateModel } from "./store";

// Если пользователь не авторизован ему запрещено посещать страницы кроме страницы авторизации и восстановления пароля
const install = (app: App, opts: { router: Router }) => {
  opts.router.beforeEach((to, from, next) => {
    const api = app.config.globalProperties.$api as Api;

    api.UserService.GetCurrentUserEvent.once((res) => {
      var isAuthorized = res.Value.IsAuthorized;
      if (
        to.name != "authorization" &&
        to.name != "password-recovery" &&
        !isAuthorized
      ) {
        next({ name: "authorization" });
      } else {
        next();
      }
    });
    api.UserService.GetCurrentUser();
  });
};

const dialog = {
  install,
};

export default dialog;
