import { WebApiService } from '../../../plugins/webApiService'
import { Events } from '../../../plugins/events'
import EventToAsyncDecorator from '../../../plugins/eventToAsyncDecorator'
import GetEndpointsStatsRequest from '../../../LoggerMicroService/Services/Models/Request/EndpointsStatisticsService/GetEndpointsStatsRequest';
import WsResponseModelT from '../../../SchemaBuilderServer/Models/WsResponseModelT';
import GetEndpointStatisticResponse from '../../../LoggerMicroService/Services/Models/Response/EndpointsStatisticsService/GetEndpointStatisticResponse';

export default class EndpointsStatisticsService {
	webApiService: WebApiService;
	GetExecuteMethodsCountEvent = new Events<WsResponseModelT<Array<GetEndpointStatisticResponse>>>();
	GetExecuteMethodsTimeEvent = new Events<WsResponseModelT<Array<GetEndpointStatisticResponse>>>();
	GetIncomingTrafficSizeEvent = new Events<WsResponseModelT<Array<GetEndpointStatisticResponse>>>();
	GetOutgoingTrafficSizeEvent = new Events<WsResponseModelT<Array<GetEndpointStatisticResponse>>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('EndpointsStatisticsService', 'GetExecuteMethodsCount', (res: WsResponseModelT<Array<GetEndpointStatisticResponse>>) => {
			self.GetExecuteMethodsCountEvent.trigger(res);
		});
		webApiService.on('EndpointsStatisticsService', 'GetExecuteMethodsTime', (res: WsResponseModelT<Array<GetEndpointStatisticResponse>>) => {
			self.GetExecuteMethodsTimeEvent.trigger(res);
		});
		webApiService.on('EndpointsStatisticsService', 'GetIncomingTrafficSize', (res: WsResponseModelT<Array<GetEndpointStatisticResponse>>) => {
			self.GetIncomingTrafficSizeEvent.trigger(res);
		});
		webApiService.on('EndpointsStatisticsService', 'GetOutgoingTrafficSize', (res: WsResponseModelT<Array<GetEndpointStatisticResponse>>) => {
			self.GetOutgoingTrafficSizeEvent.trigger(res);
		});
	}
	GetExecuteMethodsCount(request: GetEndpointsStatsRequest = null) { 	
        this.webApiService.send('EndpointsStatisticsService', 'GetExecuteMethodsCount' , request);
    }
	GetExecuteMethodsTime(request: GetEndpointsStatsRequest = null) { 	
        this.webApiService.send('EndpointsStatisticsService', 'GetExecuteMethodsTime' , request);
    }
	GetIncomingTrafficSize(request: GetEndpointsStatsRequest = null) { 	
        this.webApiService.send('EndpointsStatisticsService', 'GetIncomingTrafficSize' , request);
    }
	GetOutgoingTrafficSize(request: GetEndpointsStatsRequest = null) { 	
        this.webApiService.send('EndpointsStatisticsService', 'GetOutgoingTrafficSize' , request);
    }
	 // async methods
	async getExecuteMethodsCountAsync(request: GetEndpointsStatsRequest = null): Promise<Array<GetEndpointStatisticResponse>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetExecuteMethodsCount.bind(this), this.GetExecuteMethodsCountEvent)(request);
	}
	async getExecuteMethodsTimeAsync(request: GetEndpointsStatsRequest = null): Promise<Array<GetEndpointStatisticResponse>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetExecuteMethodsTime.bind(this), this.GetExecuteMethodsTimeEvent)(request);
	}
	async getIncomingTrafficSizeAsync(request: GetEndpointsStatsRequest = null): Promise<Array<GetEndpointStatisticResponse>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetIncomingTrafficSize.bind(this), this.GetIncomingTrafficSizeEvent)(request);
	}
	async getOutgoingTrafficSizeAsync(request: GetEndpointsStatsRequest = null): Promise<Array<GetEndpointStatisticResponse>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetOutgoingTrafficSize.bind(this), this.GetOutgoingTrafficSizeEvent)(request);
	}
}