
import { Options, Prop, Vue } from "vue-property-decorator";
import ListViewItem from "tirscript3-list-view/src/ListViewItem";
import DefaultModel from "tirscript3-component-models/src/defaultModel";
import EnumPeriodForAnalytics from "@/dataSource/api/Common/Models/Enums/EnumPeriodForAnalytic";
import FilterModel from "@/views/page/components/filter-model";

@Options({
  name: "SelectInterval",
})
export default class SelectInterval extends Vue {
  filter = new FilterModel();
  sourceLogsList: ListViewItem<DefaultModel>[] = [];
  enumList = EnumPeriodForAnalytics;

  mounted() {
    this.getSourceLogsList();
    this.filter.interval = (
      this.sourceLogsList.filter((el) => el.Selected === true)[0]
        .Value as DefaultModel
    ).id;
  }

  getSourceLogsList() {
    this.sourceLogsList.push(
      new ListViewItem(
        new DefaultModel(this.enumList.OneSecond, "1 сек."),
        true,
        false
      ),
      new ListViewItem(
        new DefaultModel(this.enumList.OneMinute, "1 мин."),
        false,
        false
      ),
      new ListViewItem(
        new DefaultModel(this.enumList.TenMinutes, "10 мин."),
        false,
        false
      ),
      new ListViewItem(
        new DefaultModel(this.enumList.ThirtyMinutes, "30 мин."),
        false,
        false
      ),
      new ListViewItem(
        new DefaultModel(this.enumList.OneHour, "1 час"),
        false,
        false
      )
    );
  }

  onChangeInterval(elements: ListViewItem<DefaultModel>) {
    this.filter.interval = elements[0].Value.id;
    this.$emit("onChangeInterval", this.filter.interval);
  }
}
