
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import ListViewItem from "tirscript3-list-view/src/ListViewItem";
import DefaultModel from "tirscript3-component-models/src/defaultModel";
import FilterModel from "@views/page/components/filter-model";
import GetEndpointsByNameResponse from "@dataSource/LoggerMicroService/Services/Models/Response/EndpointService/GetEndpointsByNameResponse";
@Options<LogsViewFilter>({
  name: "logs-wiew-filter",
  components: {},
  props: {
    // filter: { default: new FilterModel(), type: FilterModel },
    logsType: { default: true, type: Boolean },
    logsLevel: { default: true, type: Boolean },
    hardwareLogsType: { default: false, type: Boolean },
    requestId: { default: true, type: Boolean },
    url: { default: true, type: Boolean },
    addPanel: { default: true, type: Boolean },
    methods: { default: false, type: Boolean },
    connectionId: { default: true, type: Boolean },
    userId: { default: true, type: Boolean },
    sessionToken: { default: true, type: Boolean },
    ip: { default: true, type: Boolean },
    loggerName: { default: true, type: Boolean },
    shortMessage: { default: true, type: Boolean },
  },
  // watch: {
  //   filter: {
  //     deep: true,
  //     handler(value) {
  //       // console.log(`logs-view-filter`, value);
  //       //this.$emit("update:filter", value);

  //       if (this.logsLevels && this.logsLevels.length > 0) {
  //         const logsLevels = this.filter.LogLevel ? this.filter.LogLevel : -1;
  //         this.logsLevels.forEach(
  //           (el) => (el.Selected = (el.Value as DefaultModel).id == logsLevels)
  //         );
  //       }
  //       if (this.logsTypes && this.logsTypes.length > 0) {
  //         const logType = this.filter.LogType ? this.filter.LogType : -1;
  //         this.logsTypes.forEach(
  //           (el) => (el.Selected = (el.Value as DefaultModel).id == logType)
  //         );
  //       }
  //       if (this.hardwareLogsTypes && this.hardwareLogsTypes.length > 0) {
  //         const hardwareLogsType = this.filter.LogType
  //           ? this.filter.HardwareLogsType
  //           : -1;
  //         this.hardwareLogsTypes.forEach(
  //           (el) =>
  //             (el.Selected = (el.Value as DefaultModel).id == hardwareLogsType)
  //         );
  //       }
  //       if (this.controllerMethods && this.controllerMethods.length > 0) {
  //         const EndpointId = this.filter.EndpointId
  //           ? this.filter.EndpointId
  //           : -1;
  //         this.controllerMethods.forEach(
  //           (el) => (el.Selected = (el.Value as DefaultModel).id == EndpointId)
  //         );
  //       }
  //     },
  //   },
  // },
})
export default class LogsViewFilter extends Vue {
  filter: FilterModel = new FilterModel();
  readonly logsType: boolean;
  readonly "logs-level": boolean;
  readonly "hardwareLogsType": boolean;
  readonly "url": boolean;
  readonly "requestId": boolean;
  readonly "methods": boolean;
  controllerMethods: ListViewItem<DefaultModel>[] = [];
  logsLevels: ListViewItem<DefaultModel>[] = [];
  logsTypes: ListViewItem<DefaultModel>[] = [];
  hardwareLogsTypes: ListViewItem<DefaultModel>[] = [];
  searchEndpoint = "";

  @Watch("filter", { deep: true })
  onFilter(newFilter: FilterModel) {
    this.filter = newFilter;
    this.$emit("onFilter", this.filter);
  }
  mounted() {
    this.getlogsLevels();
    this.getlogsTypes();
    this.getControllerMethods();
    this.gethardwareLogsTypes();
    // document.addEventListener(
    //   "filterChange",
    //   this.onclickFilterChange.bind(this)
    // );
  }
  // onChangeDateTo(value: Date) {
  //   this.filter.DateTo = value;
  // }
  // onChangeDateFrom(value: Date) {
  //   this.filter.DateFrom = value;
  // }

  onclickFilterChange() {
    // ловим событие нажатия кнопки отфильтровать
    // debugger;
    // this.$emit("update:filter", this.filterModel);
  }
  onChangeLogsType(items: ListViewItem<DefaultModel>[]) {
    let id = (
      items.filter((el) => el.Selected === true)[0].Value as DefaultModel
    ).id;
    if (id == -1) id = null;
    this.filter.LogType = id;
  }
  onChangeHardwareLogsType(items: ListViewItem<DefaultModel>[]) {
    let id = (
      items.filter((el) => el.Selected === true)[0].Value as DefaultModel
    ).id;
    if (id == -1) id = null;
    this.filter.HardwareLogsType = id;
  }
  onChangeLogsLevel(items: ListViewItem<DefaultModel>[]) {
    let id = (
      items.filter((el) => el.Selected === true)[0].Value as DefaultModel
    ).id;
    if (id == -1) id = null;
    this.filter.LogLevel = id;
  }
  onChangeControllerMethods(items: ListViewItem<DefaultModel>[]) {
    let id = (
      items.filter((el) => el.Selected === true)[0].Value as DefaultModel
    ).id;
    if (id == -1) id = null;
    this.filter.EndpointId = id;
  }

  getlogsLevels() {
    this.logsLevels = [
      new ListViewItem(new DefaultModel(null, "All logs level"), true, false),
      new ListViewItem(new DefaultModel(0, "Debug"), false, false),
      new ListViewItem(new DefaultModel(1, "Trace"), false, false),
      new ListViewItem(new DefaultModel(2, "Info"), false, false),
      new ListViewItem(new DefaultModel(3, "Warn"), false, false),
      new ListViewItem(new DefaultModel(4, "Error"), false, false),
    ];
    this.filter.LogLevel = (this.logsLevels[0].Value as DefaultModel).id;
  }
  onChangeAutocomlite(value: string) {
    this.searchEndpoint = value;
    this.getControllerMethods();
  }
  async getControllerMethods() {
    if (!this.methods) {
      return;
    }
    this.controllerMethods = [];

    this.controllerMethods.push(
      new ListViewItem(
        new DefaultModel(null, "Любой контроллер/метод"),
        true,
        false
      )
    );
    let res: GetEndpointsByNameResponse = null;
    try {
      res = await this.$api.EndpointService.getEndpointsAsync({
        Page: { Skip: 0, Take: 20 },
        Search: this.searchEndpoint,
      });
    } catch (error) {
      return;
    }

    res.Items.forEach((el) => {
      this.controllerMethods.push(
        new ListViewItem(
          new DefaultModel(el.Id, `${el.Class}/${el.Method}`),
          false,
          false
        )
      );
    });
    this.filter.controllerMethod = (
      this.controllerMethods[0].Value as DefaultModel
    ).id;
  }
  getlogsTypes() {
    this.logsTypes = [
      new ListViewItem(new DefaultModel(null, "All logs types"), true, false),
    ];
    for (let index = 0; index < 11; index++) {
      this.logsTypes.push(
        new ListViewItem(
          new DefaultModel(index, this.$filters.LogType(index).toString()),
          false,
          false
        )
      );
    }
    this.filter.LogType = (this.logsTypes[0].Value as DefaultModel).id;
  }
  gethardwareLogsTypes() {
    this.hardwareLogsTypes = [
      new ListViewItem(
        new DefaultModel(-1, "Все типы мониторинга"),
        true,
        false
      ),
    ];
    for (let index = 0; index < 2; index++) {
      this.hardwareLogsTypes.push(
        new ListViewItem(
          new DefaultModel(
            index,
            this.$filters.HardwareLogType(index).toString()
          ),
          false,
          false
        )
      );
    }
    this.filter.HardwareLogsType = (
      this.hardwareLogsTypes[0].Value as DefaultModel
    ).id;
  }
  clearFilter() {
    this.filter.Url = "";
    this.filter.LogType = null;
    this.filter.LogLevel = null;
    this.filter.HardwareLogsType = null;
    this.filter.Url = "";
    this.filter.SessionToken = "";
    this.filter.Ip = "";
    this.filter.UserId = "";
    this.filter.ConnectionId = "";
    this.filter.RequestId = "";
    this.filter.ProjectLogGroupId = null;
    this.filter.LoggerName = "";
    this.filter.ShortMessage = "";
  }
}
