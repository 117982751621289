<template>
  <header class="header">
    <div class="header__mobile-btn" @click="onClickBurger">
      <transition name="fade">
        <img
          v-if="hideMenu"
          src="/src/assets/img/burger-white.svg"
          style="top: -14px"
        />
        <img
          v-else
          src="@assets/img/arrow-left.svg"
          alt=""
          srcset=""
          style="height: 20px; top: -10px; left: 5px"
        />
      </transition>
    </div>

    <!-- <div class="logo-block">
      <img alt="" src="@assets/img/logo.png" />
    </div> -->
    <div class="header__update">
      <div class="icon"><span class="icon-apteka-database"></span></div>
    </div>
    <div class="header__user-panel">
      <div class="header__user-photo" @click="changeProfile">
        <!-- <span class="icon-apteka-photo"></span> -->
        <img src="@assets/img/no_image.svg" alt="" class="ico-image" />
      </div>
      <div class="header__user-text">
        <div class="user__name" @click="changeProfile" title="Войти в профиль">
          {{ userName }}
        </div>
        <div class="user" @click.stop="onLogout">
          <a href="#" title="Выход из учетной записи">Выход</a>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import api from "@/api";
import Cookies from "js-cookie";
import { Options } from "vue-class-component";
import { Vue } from "vue-property-decorator";

export default class LayoutHeader extends Vue {
  hideMenu: boolean;
  userName = "";
  burger = false;
  // Получаем имя текущего пользователя
  async created() {
    this.$api.UserService.GetCurrentUserEvent.once((res) => {
      if (res.IsSuccess) {
        this.userName = res.Value.Email;
      }
    });
    this.$api.UserService.GetCurrentUser();
  }

  changeProfile() {
    this.$router.push({ name: "profile" });
    this.$emit("changeProfile");
  }
  // Выход из аккаунта
  onLogout() {
    this.$api.UserService.UserLogoutEvent.once((res) => {
      if (res.IsSuccess) {
        Cookies.remove("Auth-Token");
        window.open("authorization", "_self");
      }
    });
    this.$api.UserService.UserLogout();
  }

  onClickBurger() {
    this.hideMenu = !this.hideMenu;
    this.$emit("update:hideMenu", this.hideMenu);
  }
}
</script>

<style lang="less">
header {
  height: 74px;
  background: rgb(34, 26, 58);
  display: flex;
  align-items: center;
  color: #fff;
  .header__mobile-btn {
    margin-left: 10px;

    cursor: pointer;
    position: relative;
    > img {
      position: absolute;
    }
  }
  .logo-block {
    width: 35px;
    img {
      width: 100%;
    }
  }
  .header__update {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 30px;
    padding-left: 30px;
    border-right: 1px solid #176363;
    cursor: pointer;
    .icon {
      width: 41px;
      height: 41px;
      border-radius: 50%;
      background: #322757;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(255, 255, 255, 0.7);
    }
  }
  .header__user-panel {
    padding-right: 30px;
    padding-left: 30px;
    min-width: 195px;
    display: flex;
    align-items: center;
    .header__user-photo {
      flex: 41px 0 0;
      width: 41px;
      height: 41px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #0c3030;
      border-radius: 50%;
      margin-right: 14px;
      .ico-image {
        height: 100%;
        width: 100%;
        cursor: pointer;
      }
    }
    .header__user-text {
      overflow: hidden;
      a {
        color: #fff;
      }
      .user__name {
        color: #bac6da;
        cursor: pointer;
      }
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.6s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>