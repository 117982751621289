enum EnumLogType {
	Other = 0,
	HttpPost = 1,
	HttpGet = 2,
	Debug = 3,
	WsDebug = 4,
	WsOnOpen = 5,
	WsOnMessage = 6,
	WsOnError = 7,
	WsOnClose = 8,
	WsDispose = 9,
	WsConnecting = 10,
	WsConnection = 11,
	SchedulerStart = 13,
	SchedulerStop = 14,
	CpuSurge = 15,
	ApplicationStart = 16,
	ApplicationStop = 17,
	ScheduleJobLog = 18
}
export default EnumLogType
