
import { Options, Vue } from "vue-class-component";
import StatusWindow from "modal-window/src/status-window";
import DialogModal, { DialogButton } from "./model/dialogModal";
// import UiModalWindow from "@/views/components/tirscript3-modal-window/tirscript3-modal-window.vue";
import { Events } from "@dataSource/plugins/events";
// import UiButton from "@views/components/tirscript3-button.vue";
@Options({
  props: {
    eventBus: Events,
  },
  components: {
    // UiModalWindow,
    // UiButton,
  },
  emits: ["change"],
})
export default class UiDialog extends Vue {
  type: string = "";
  visible = false;
  eventBus = new Events<DialogModal>();
  dialogModal: DialogModal = new DialogModal();
  created() {
    this.eventBus.on(this.show);
  }
  getButtonStyle(item: DialogButton): Object {
    return {
      background: item.BgColor,
      color: item.Color,
    };
  }
  toModalType(): StatusWindow {
    // switch(this.dialogModal.Type) {
    //     // case
    // }
    return StatusWindow.Text;
  }
  onClose() {
    this.clickButton(undefined);
  }
  clickButton(result: any) {
    this.hide();
    this.$emit("change", result);
    this.dialogModal?.Callback(result);
  }
  hide() {
    this.visible = false;
  }
  show(data: DialogModal) {
    this.dialogModal = data;
    this.visible = true;
  }

  mounted() {
    // console.log("mounted");
  }
}
