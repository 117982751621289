import { WebApiService } from '../../../plugins/webApiService'
import { Events } from '../../../plugins/events'
import EventToAsyncDecorator from '../../../plugins/eventToAsyncDecorator'
import GetEndpointsByNameRequest from '../../../LoggerMicroService/Services/Models/Request/EndpointService/GetEndpointsByNameRequest';
import WsResponseModelT from '../../../SchemaBuilderServer/Models/WsResponseModelT';
import GetEndpointsByNameResponse from '../../../LoggerMicroService/Services/Models/Response/EndpointService/GetEndpointsByNameResponse';

export default class EndpointService {
	webApiService: WebApiService;
	GetEndpointsEvent = new Events<WsResponseModelT<GetEndpointsByNameResponse>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('EndpointService', 'GetEndpoints', (res: WsResponseModelT<GetEndpointsByNameResponse>) => {
			self.GetEndpointsEvent.trigger(res);
		});
	}
	GetEndpoints(request: GetEndpointsByNameRequest = null) { 	
        this.webApiService.send('EndpointService', 'GetEndpoints' , request);
    }
	 // async methods
	async getEndpointsAsync(request: GetEndpointsByNameRequest = null): Promise<GetEndpointsByNameResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetEndpoints.bind(this), this.GetEndpointsEvent)(request);
	}
}