import { WebApiService } from '../../../plugins/webApiService'
import { Events } from '../../../plugins/events'
import EventToAsyncDecorator from '../../../plugins/eventToAsyncDecorator'
import GetTopEndpointLogsRequest from '../../../LoggerMicroService/Services/Models/Request/TopEndpointLogsService/GetTopEndpointLogsRequest';
import GetCurrentEndpointStatisticsRequest from '../../../LoggerMicroService/Services/Models/Request/TopEndpointLogsService/GetCurrentEndpointStatisticsRequest';
import WsResponseModelT from '../../../SchemaBuilderServer/Models/WsResponseModelT';
import GetTopEndpointLogsResponse from '../../../LoggerMicroService/Services/Models/Response/TopEndpointLogsService/GetTopEndpointLogsResponse';
import GetEndpointStatisticsResponse from '../../../LoggerMicroService/Services/Models/Response/TopEndpointLogsService/GetEndpointStatisticsResponse';

export default class TopEndpointLogsService {
	webApiService: WebApiService;
	GetTopLongRequestsEvent = new Events<WsResponseModelT<GetTopEndpointLogsResponse>>();
	GetTopFatRequestsEvent = new Events<WsResponseModelT<GetTopEndpointLogsResponse>>();
	GetTopFatResponsesEvent = new Events<WsResponseModelT<GetTopEndpointLogsResponse>>();
	GetEndpointsStatisticsEvent = new Events<WsResponseModelT<GetEndpointStatisticsResponse>>();
	GetCurrentEndpointsStatisticsEvent = new Events<WsResponseModelT<GetEndpointStatisticsResponse>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('TopEndpointLogsService', 'GetTopLongRequests', (res: WsResponseModelT<GetTopEndpointLogsResponse>) => {
			self.GetTopLongRequestsEvent.trigger(res);
		});
		webApiService.on('TopEndpointLogsService', 'GetTopFatRequests', (res: WsResponseModelT<GetTopEndpointLogsResponse>) => {
			self.GetTopFatRequestsEvent.trigger(res);
		});
		webApiService.on('TopEndpointLogsService', 'GetTopFatResponses', (res: WsResponseModelT<GetTopEndpointLogsResponse>) => {
			self.GetTopFatResponsesEvent.trigger(res);
		});
		webApiService.on('TopEndpointLogsService', 'GetEndpointsStatistics', (res: WsResponseModelT<GetEndpointStatisticsResponse>) => {
			self.GetEndpointsStatisticsEvent.trigger(res);
		});
		webApiService.on('TopEndpointLogsService', 'GetCurrentEndpointsStatistics', (res: WsResponseModelT<GetEndpointStatisticsResponse>) => {
			self.GetCurrentEndpointsStatisticsEvent.trigger(res);
		});
	}
	GetTopLongRequests(request: GetTopEndpointLogsRequest = null) { 	
        this.webApiService.send('TopEndpointLogsService', 'GetTopLongRequests' , request);
    }
	GetTopFatRequests(request: GetTopEndpointLogsRequest = null) { 	
        this.webApiService.send('TopEndpointLogsService', 'GetTopFatRequests' , request);
    }
	GetTopFatResponses(request: GetTopEndpointLogsRequest = null) { 	
        this.webApiService.send('TopEndpointLogsService', 'GetTopFatResponses' , request);
    }
	GetEndpointsStatistics(request: GetTopEndpointLogsRequest = null) { 	
        this.webApiService.send('TopEndpointLogsService', 'GetEndpointsStatistics' , request);
    }
	GetCurrentEndpointsStatistics(request: GetCurrentEndpointStatisticsRequest = null) { 	
        this.webApiService.send('TopEndpointLogsService', 'GetCurrentEndpointsStatistics' , request);
    }
	 // async methods
	async getTopLongRequestsAsync(request: GetTopEndpointLogsRequest = null): Promise<GetTopEndpointLogsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetTopLongRequests.bind(this), this.GetTopLongRequestsEvent)(request);
	}
	async getTopFatRequestsAsync(request: GetTopEndpointLogsRequest = null): Promise<GetTopEndpointLogsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetTopFatRequests.bind(this), this.GetTopFatRequestsEvent)(request);
	}
	async getTopFatResponsesAsync(request: GetTopEndpointLogsRequest = null): Promise<GetTopEndpointLogsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetTopFatResponses.bind(this), this.GetTopFatResponsesEvent)(request);
	}
	async getEndpointsStatisticsAsync(request: GetTopEndpointLogsRequest = null): Promise<GetEndpointStatisticsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetEndpointsStatistics.bind(this), this.GetEndpointsStatisticsEvent)(request);
	}
	async getCurrentEndpointsStatisticsAsync(request: GetCurrentEndpointStatisticsRequest = null): Promise<GetEndpointStatisticsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetCurrentEndpointsStatistics.bind(this), this.GetCurrentEndpointsStatisticsEvent)(request);
	}
}