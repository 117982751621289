import EnumLogType from "@/dataSource/api/Tirscript/LoggerMicroService/Enums/EnumLogType";

export default function LogType(value: EnumLogType): string {  
  return EnumLogType[value];
  // switch (value) {
  //   case 0:
  //     return "Other";
  //   case 1:
  //     return "HttpPost";
  //   case 2:
  //     return "HttpGet";
  //   case 3:
  //     return "Debug";
  //   case 4:
  //     return "WsDebug";
  //   case 5:
  //     return "WsOnOpen";
  //   case 6:
  //     return "WsOnMessage";
  //   case 7:
  //     return "WsOnError";
  //   case 8:
  //     return "WsOnClose";
  //   case 9:
  //     return "WsDispose";
  //   case 10:
  //     return "WsConnect";
  //   default:
  //     return "Type-"+value;
  // }
}
// 	Other = 0,
// HttpPost = 1,
// HttpGet = 2,
// Debug = 3,
// WsDebug = 4,
// WsOnOpen = 5,
// WsOnMessage = 6,
// WsOnError = 7,
// WsOnClose = 8,
// WsDispose = 9,
// WsConnect = 10
