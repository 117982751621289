import { WebApiService } from '../../../plugins/webApiService'
import { Events } from '../../../plugins/events'
import EventToAsyncDecorator from '../../../plugins/eventToAsyncDecorator'
import WsResponseModelT from '../../../SchemaBuilderServer/Models/WsResponseModelT';
import GetLogLevelsResponse from '../../../LoggerMicroService/Services/Models/Response/GetLogLevelsResponse';

export default class LogLevelService {
	webApiService: WebApiService;
	GetLogLevelsEvent = new Events<WsResponseModelT<GetLogLevelsResponse>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('LogLevelService', 'GetLogLevels', (res: WsResponseModelT<GetLogLevelsResponse>) => {
			self.GetLogLevelsEvent.trigger(res);
		});
	}
	GetLogLevels() { 	
        this.webApiService.send('LogLevelService', 'GetLogLevels' );
    }
	 // async methods
	async getLogLevelsAsync(): Promise<GetLogLevelsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetLogLevels.bind(this), this.GetLogLevelsEvent)();
	}
}