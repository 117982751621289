import { WebApiService } from '../../plugins/webApiService'
import { Events } from '../../plugins/events'
import EventToAsyncDecorator from '../../plugins/eventToAsyncDecorator'
import StatLogRequest from '../../AnalyticLogs/IServices/Models/Request/StatLogRequest';
import WsResponseModelT from '../../SchemaBuilderServer/Models/WsResponseModelT';
import StatLogResponseGrouped from '../../AnalyticLogs/IServices/Models/Response/StatLogResponseGrouped';
import StatLogResponseGroupedNoAnswered from '../../AnalyticLogs/IServices/Models/Response/StatLogResponseGroupedNoAnswered';

export default class AnalyticLogService {
	webApiService: WebApiService;
	GetLogsStatisticEvent = new Events<WsResponseModelT<Array<StatLogResponseGrouped>>>();
	GetLogsStatisticNoAnsweredEvent = new Events<WsResponseModelT<Array<StatLogResponseGroupedNoAnswered>>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('AnalyticLogService', 'GetLogsStatistic', (res: WsResponseModelT<Array<StatLogResponseGrouped>>) => {
			self.GetLogsStatisticEvent.trigger(res);
		});
		webApiService.on('AnalyticLogService', 'GetLogsStatisticNoAnswered', (res: WsResponseModelT<Array<StatLogResponseGroupedNoAnswered>>) => {
			self.GetLogsStatisticNoAnsweredEvent.trigger(res);
		});
	}
	GetLogsStatistic(request: StatLogRequest = null) { 	
        this.webApiService.send('AnalyticLogService', 'GetLogsStatistic' , request);
    }
	GetLogsStatisticNoAnswered(request: StatLogRequest = null) { 	
        this.webApiService.send('AnalyticLogService', 'GetLogsStatisticNoAnswered' , request);
    }
	 // async methods
	async getLogsStatisticAsync(request: StatLogRequest = null): Promise<Array<StatLogResponseGrouped>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetLogsStatistic.bind(this), this.GetLogsStatisticEvent)(request);
	}
	async getLogsStatisticNoAnsweredAsync(request: StatLogRequest = null): Promise<Array<StatLogResponseGroupedNoAnswered>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetLogsStatisticNoAnswered.bind(this), this.GetLogsStatisticNoAnsweredEvent)(request);
	}
}