import EnumLogLevel from "@/dataSource/api/Tirscript/LoggerMicroService/Enums/EnumLogLevel";

export default function LogLevel(value: EnumLogLevel): string {
  //let enum1:EnumLogLevel = value;
  return EnumLogLevel[value];
  // switch (value) {
  //   case 0:
  //     return "Debug";
  //   case 1:
  //     return "Trace";
  //   case 2:
  //     return "Info";
  //   case 3:
  //     return "Warn";
  //   case 4:
  //     return "Error";

  //   default:
  //     return value +"(Unknown)";
  // }
}
// 	Debug = 0,
// Trace = 1,
// Info = 3,
// Warn = 4,
// Error = 5
