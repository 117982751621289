import { WebApiService } from '../../../plugins/webApiService'
import { Events } from '../../../plugins/events'
import EventToAsyncDecorator from '../../../plugins/eventToAsyncDecorator'
import WsResponseModelT from '../../../SchemaBuilderServer/Models/WsResponseModelT';
import GetAllProjectLogGroupsResponse from '../../../LoggerMicroService/Services/Models/Response/GetAllProjectLogGroupsResponse';

export default class ProjectLogGroupService {
	webApiService: WebApiService;
	GetProjectLogGroupsEvent = new Events<WsResponseModelT<Array<GetAllProjectLogGroupsResponse>>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('ProjectLogGroupService', 'GetProjectLogGroups', (res: WsResponseModelT<Array<GetAllProjectLogGroupsResponse>>) => {
			self.GetProjectLogGroupsEvent.trigger(res);
		});
	}
	GetProjectLogGroups() { 	
        this.webApiService.send('ProjectLogGroupService', 'GetProjectLogGroups' );
    }
	 // async methods
	async getProjectLogGroupsAsync(): Promise<Array<GetAllProjectLogGroupsResponse>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetProjectLogGroups.bind(this), this.GetProjectLogGroupsEvent)();
	}
}