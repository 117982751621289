import { WebApiService } from '../../../plugins/webApiService'
import { Events } from '../../../plugins/events'
import EventToAsyncDecorator from '../../../plugins/eventToAsyncDecorator'
import GetIndicatorsRequest from '../../../LoggerMicroService/Services/Models/Request/GetIndicatorsRequest';
import WsResponseModelT from '../../../SchemaBuilderServer/Models/WsResponseModelT';
import GetIndicatorsResponse from '../../../LoggerMicroService/Services/Models/Response/GetIndicatorsResponse';

export default class IndicatorsService {
	webApiService: WebApiService;
	GetIndicatorsEvent = new Events<WsResponseModelT<GetIndicatorsResponse>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('IndicatorsService', 'GetIndicators', (res: WsResponseModelT<GetIndicatorsResponse>) => {
			self.GetIndicatorsEvent.trigger(res);
		});
	}
	GetIndicators(request: GetIndicatorsRequest = null) { 	
        this.webApiService.send('IndicatorsService', 'GetIndicators' , request);
    }
	 // async methods
	async getIndicatorsAsync(request: GetIndicatorsRequest = null): Promise<GetIndicatorsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetIndicators.bind(this), this.GetIndicatorsEvent)(request);
	}
}