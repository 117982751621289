import { WebApiService } from '../../plugins/webApiService'
import { Events } from '../../plugins/events'
import EventToAsyncDecorator from '../../plugins/eventToAsyncDecorator'
import StatRequest from '../../AnalyticEndpoints/IServices/Models/Request/StatRequest';
import EndpointsRequest from '../../AnalyticEndpoints/IServices/Models/Request/EndpointsRequest';
import EndpointTimeStatisticRequest from '../../AnalyticEndpoints/IServices/Models/Request/EndpointTimeStatisticRequest';
import EndpointUsersStatisticRequest from '../../AnalyticEndpoints/IServices/Models/Request/EndpointUsersStatisticRequest';
import EndpointsTimeStatisticAllRequest from '../../AnalyticEndpoints/IServices/Models/Request/EndpointsTimeStatisticAllRequest';
import SessionStatisticRequest from '../../AnalyticEndpoints/IServices/Models/Request/SessionStatisticRequest';
import WsResponseModelT from '../../SchemaBuilderServer/Models/WsResponseModelT';
import StatResponseGrouped from '../../AnalyticEndpoints/IServices/Models/Response/StatResponseGrouped';
import StatResponseGroupedNoAnswered from '../../AnalyticEndpoints/IServices/Models/Response/StatResponseGroupedNoAnswered';
import EndpointsResponse from '../../AnalyticEndpoints/IServices/Models/Response/EndpointsResponse';
import EndpointTimeStatisticResponse from '../../AnalyticEndpoints/IServices/Models/Response/EndpointTimeStatisticResponse';
import EndpointUsersStatisticResponse from '../../AnalyticEndpoints/IServices/Models/Response/EndpointUsersStatisticResponse';
import EndpointsTimeStatisticAllResponse from '../../AnalyticEndpoints/IServices/Models/Response/EndpointsTimeStatisticAllResponse';
import SessionStatisticResponse from '../../AnalyticEndpoints/IServices/Models/Response/SessionStatisticResponse';

export default class AnalyticEndpointsService {
	webApiService: WebApiService;
	GetStatisticEvent = new Events<WsResponseModelT<Array<StatResponseGrouped>>>();
	GetStatisticNoAnsweredEvent = new Events<WsResponseModelT<Array<StatResponseGroupedNoAnswered>>>();
	GetEndpointsEvent = new Events<WsResponseModelT<EndpointsResponse>>();
	GetEndpointTimeStatisticEvent = new Events<WsResponseModelT<Array<EndpointTimeStatisticResponse>>>();
	GetEndpointUsersStatisticEvent = new Events<WsResponseModelT<Array<EndpointUsersStatisticResponse>>>();
	GetAllEndpointsTimeStatisticEvent = new Events<WsResponseModelT<Array<EndpointsTimeStatisticAllResponse>>>();
	GetSessionStatisticEvent = new Events<WsResponseModelT<Array<SessionStatisticResponse>>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('AnalyticEndpointsService', 'GetStatistic', (res: WsResponseModelT<Array<StatResponseGrouped>>) => {
			self.GetStatisticEvent.trigger(res);
		});
		webApiService.on('AnalyticEndpointsService', 'GetStatisticNoAnswered', (res: WsResponseModelT<Array<StatResponseGroupedNoAnswered>>) => {
			self.GetStatisticNoAnsweredEvent.trigger(res);
		});
		webApiService.on('AnalyticEndpointsService', 'GetEndpoints', (res: WsResponseModelT<EndpointsResponse>) => {
			self.GetEndpointsEvent.trigger(res);
		});
		webApiService.on('AnalyticEndpointsService', 'GetEndpointTimeStatistic', (res: WsResponseModelT<Array<EndpointTimeStatisticResponse>>) => {
			self.GetEndpointTimeStatisticEvent.trigger(res);
		});
		webApiService.on('AnalyticEndpointsService', 'GetEndpointUsersStatistic', (res: WsResponseModelT<Array<EndpointUsersStatisticResponse>>) => {
			self.GetEndpointUsersStatisticEvent.trigger(res);
		});
		webApiService.on('AnalyticEndpointsService', 'GetAllEndpointsTimeStatistic', (res: WsResponseModelT<Array<EndpointsTimeStatisticAllResponse>>) => {
			self.GetAllEndpointsTimeStatisticEvent.trigger(res);
		});
		webApiService.on('AnalyticEndpointsService', 'GetSessionStatistic', (res: WsResponseModelT<Array<SessionStatisticResponse>>) => {
			self.GetSessionStatisticEvent.trigger(res);
		});
	}
	GetStatistic(request: StatRequest = null) { 	
        this.webApiService.send('AnalyticEndpointsService', 'GetStatistic' , request);
    }
	GetStatisticNoAnswered(request: StatRequest = null) { 	
        this.webApiService.send('AnalyticEndpointsService', 'GetStatisticNoAnswered' , request);
    }
	GetEndpoints(request: EndpointsRequest = null) { 	
        this.webApiService.send('AnalyticEndpointsService', 'GetEndpoints' , request);
    }
	GetEndpointTimeStatistic(request: EndpointTimeStatisticRequest = null) { 	
        this.webApiService.send('AnalyticEndpointsService', 'GetEndpointTimeStatistic' , request);
    }
	GetEndpointUsersStatistic(request: EndpointUsersStatisticRequest = null) { 	
        this.webApiService.send('AnalyticEndpointsService', 'GetEndpointUsersStatistic' , request);
    }
	GetAllEndpointsTimeStatistic(request: EndpointsTimeStatisticAllRequest = null) { 	
        this.webApiService.send('AnalyticEndpointsService', 'GetAllEndpointsTimeStatistic' , request);
    }
	GetSessionStatistic(request: SessionStatisticRequest = null) { 	
        this.webApiService.send('AnalyticEndpointsService', 'GetSessionStatistic' , request);
    }
	 // async methods
	async getStatisticAsync(request: StatRequest = null): Promise<Array<StatResponseGrouped>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetStatistic.bind(this), this.GetStatisticEvent)(request);
	}
	async getStatisticNoAnsweredAsync(request: StatRequest = null): Promise<Array<StatResponseGroupedNoAnswered>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetStatisticNoAnswered.bind(this), this.GetStatisticNoAnsweredEvent)(request);
	}
	async getEndpointsAsync(request: EndpointsRequest = null): Promise<EndpointsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetEndpoints.bind(this), this.GetEndpointsEvent)(request);
	}
	async getEndpointTimeStatisticAsync(request: EndpointTimeStatisticRequest = null): Promise<Array<EndpointTimeStatisticResponse>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetEndpointTimeStatistic.bind(this), this.GetEndpointTimeStatisticEvent)(request);
	}
	async getEndpointUsersStatisticAsync(request: EndpointUsersStatisticRequest = null): Promise<Array<EndpointUsersStatisticResponse>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetEndpointUsersStatistic.bind(this), this.GetEndpointUsersStatisticEvent)(request);
	}
	async getAllEndpointsTimeStatisticAsync(request: EndpointsTimeStatisticAllRequest = null): Promise<Array<EndpointsTimeStatisticAllResponse>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetAllEndpointsTimeStatistic.bind(this), this.GetAllEndpointsTimeStatisticEvent)(request);
	}
	async getSessionStatisticAsync(request: SessionStatisticRequest = null): Promise<Array<SessionStatisticResponse>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetSessionStatistic.bind(this), this.GetSessionStatisticEvent)(request);
	}
}