import { createApp } from "vue";
import App from "./app.vue";
import router from "./router";
import Store from "@/plugins/dataStore/Store";
import api from "./api";

// Импорт компонентов
import registerComponents from "@/register-components";

//plagins
import ErrorHandler from "./plugins/errorHandler";
import UiDialog from "./plugins/dialog/plugin.dialog";
import Notification from "./plugins/notification/plugin.notification";
import Locale from "./plugins/localization/plugin.localization";
import AuthMiddleware from "@/authMiddleware";

import registerFilters from "./filters/filters";
import { xhr } from "ol/featureloader";

const app = createApp(App);

// Регистрация компонентов
registerComponents(app);

// Подключение API
var scheme = location.protocol.replace("http", "ws");
var url = scheme + "//logger-admin-dev.progressme.ru/websocket";
if (location.hostname !== 'localhost')
  url = scheme + "//" + location.host + "/websocket";

app.use(api, { url: url });

app.use(Store);
app.use(AuthMiddleware, { router });
app.use(Notification, {});
app.use(UiDialog, {});
app.use(Locale, {});
app.use(ErrorHandler, {
  handler: (err: Error) => {
    app.config.globalProperties.$notification.error(err);
  },
});

registerFilters(app);

app.use(router);

app.mount("#app");
