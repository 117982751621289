import { WebApiService } from '../../plugins/webApiService'
import { Events } from '../../plugins/events'
import EventToAsyncDecorator from '../../plugins/eventToAsyncDecorator'
import UsersWithMaxRequestsCountRequest from '../../AnalyticUsers/IService/Models/Request/UsersWithMaxRequestsCountRequest';
import GetCountEndPointsByUserIdRequest from '../../AnalyticUsers/IService/Models/Request/GetCountEndPointsByUserIdRequest';
import ActiveUsersRequest from '../../AnalyticUsers/IService/Models/Request/ActiveUsersRequest';
import UsersWithTheirEndpointsRequest from '../../AnalyticUsers/IService/Models/Request/UsersWithTheirEndpointsRequest';
import WsResponseModelT from '../../SchemaBuilderServer/Models/WsResponseModelT';
import UsersWithMaxEndpointRequestsCountResponse from '../../AnalyticUsers/IService/Models/Response/UsersWithMaxEndpointRequestsCountResponse';
import GetCountEndPointsByUserIdGroupedResponse from '../../AnalyticUsers/IService/Models/Response/GetCountEndPointsByUserIdGroupedResponse';
import ActiveUsersResponse from '../../AnalyticUsers/IService/Models/Response/ActiveUsersResponse';
import UsersWithTheirEndpointsResponse from '../../AnalyticUsers/IService/Models/Response/UsersWithTheirEndpointsResponse';

export default class AnalyticUsersService {
	webApiService: WebApiService;
	GetUsersWithMaxEndpointRequestsCountEvent = new Events<WsResponseModelT<Array<UsersWithMaxEndpointRequestsCountResponse>>>();
	GetCountEndPointsByUserIdEvent = new Events<WsResponseModelT<Array<GetCountEndPointsByUserIdGroupedResponse>>>();
	GetActiveUsersEvent = new Events<WsResponseModelT<Array<ActiveUsersResponse>>>();
	GetUsersWithTheirEndpointsEvent = new Events<WsResponseModelT<UsersWithTheirEndpointsResponse>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('AnalyticUsersService', 'GetUsersWithMaxEndpointRequestsCount', (res: WsResponseModelT<Array<UsersWithMaxEndpointRequestsCountResponse>>) => {
			self.GetUsersWithMaxEndpointRequestsCountEvent.trigger(res);
		});
		webApiService.on('AnalyticUsersService', 'GetCountEndPointsByUserId', (res: WsResponseModelT<Array<GetCountEndPointsByUserIdGroupedResponse>>) => {
			self.GetCountEndPointsByUserIdEvent.trigger(res);
		});
		webApiService.on('AnalyticUsersService', 'GetActiveUsers', (res: WsResponseModelT<Array<ActiveUsersResponse>>) => {
			self.GetActiveUsersEvent.trigger(res);
		});
		webApiService.on('AnalyticUsersService', 'GetUsersWithTheirEndpoints', (res: WsResponseModelT<UsersWithTheirEndpointsResponse>) => {
			self.GetUsersWithTheirEndpointsEvent.trigger(res);
		});
	}
	GetUsersWithMaxEndpointRequestsCount(request: UsersWithMaxRequestsCountRequest = null) { 	
        this.webApiService.send('AnalyticUsersService', 'GetUsersWithMaxEndpointRequestsCount' , request);
    }
	GetCountEndPointsByUserId(request: GetCountEndPointsByUserIdRequest = null) { 	
        this.webApiService.send('AnalyticUsersService', 'GetCountEndPointsByUserId' , request);
    }
	GetActiveUsers(request: ActiveUsersRequest = null) { 	
        this.webApiService.send('AnalyticUsersService', 'GetActiveUsers' , request);
    }
	GetUsersWithTheirEndpoints(request: UsersWithTheirEndpointsRequest = null) { 	
        this.webApiService.send('AnalyticUsersService', 'GetUsersWithTheirEndpoints' , request);
    }
	 // async methods
	async getUsersWithMaxEndpointRequestsCountAsync(request: UsersWithMaxRequestsCountRequest = null): Promise<Array<UsersWithMaxEndpointRequestsCountResponse>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetUsersWithMaxEndpointRequestsCount.bind(this), this.GetUsersWithMaxEndpointRequestsCountEvent)(request);
	}
	async getCountEndPointsByUserIdAsync(request: GetCountEndPointsByUserIdRequest = null): Promise<Array<GetCountEndPointsByUserIdGroupedResponse>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetCountEndPointsByUserId.bind(this), this.GetCountEndPointsByUserIdEvent)(request);
	}
	async getActiveUsersAsync(request: ActiveUsersRequest = null): Promise<Array<ActiveUsersResponse>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetActiveUsers.bind(this), this.GetActiveUsersEvent)(request);
	}
	async getUsersWithTheirEndpointsAsync(request: UsersWithTheirEndpointsRequest = null): Promise<UsersWithTheirEndpointsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetUsersWithTheirEndpoints.bind(this), this.GetUsersWithTheirEndpointsEvent)(request);
	}
}