import { WebApiService } from '../../plugins/webApiService'
import { Events } from '../../plugins/events'
import EventToAsyncDecorator from '../../plugins/eventToAsyncDecorator'
import RequestLogin from '../../Users/IServices/Models/Request/RequestLogin';
import InviteUserRequest from '../../Users/IServices/Models/Request/InviteUserRequest';
import ActivateUserRequest from '../../Users/IServices/Models/Request/ActivateUserRequest';
import GetUsersRequest from '../../Users/IServices/Models/Request/GetUsersRequest';
import ChangePasswordUserRequest from '../../Users/IServices/Models/Request/ChangePasswordUserRequest';
import ResetPasswordGetTokenRequest from '../../Users/IServices/Models/Request/ResetPasswordGetTokenRequest';
import ResetPasswordRequest from '../../Users/IServices/Models/Request/ResetPasswordRequest';
import WsResponseModelT from '../../SchemaBuilderServer/Models/WsResponseModelT';
import SearchUsersResponse from '../../Users/IServices/Models/Response/SearchUsersResponse';
import UserIdentity from '../../LoggerMicroService/IdentityService/UserIdentity';

export default class UserService {
	webApiService: WebApiService;
	UserLoginEvent = new Events<WsResponseModelT<Boolean>>();
	UserLogoutEvent = new Events<WsResponseModelT<Boolean>>();
	InviteUserEvent = new Events<WsResponseModelT<Boolean>>();
	AcceptInvationEvent = new Events<WsResponseModelT<Boolean>>();
	GetUsersEvent = new Events<WsResponseModelT<SearchUsersResponse>>();
	BlockUserEvent = new Events<WsResponseModelT<Boolean>>();
	ChangePasswordEvent = new Events<WsResponseModelT<Boolean>>();
	ResetPasswordGetTokenEvent = new Events<WsResponseModelT<Boolean>>();
	ResetPasswordEvent = new Events<WsResponseModelT<Boolean>>();
	GetCurrentUserEvent = new Events<WsResponseModelT<UserIdentity>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('UserService', 'UserLogin', (res: WsResponseModelT<Boolean>) => {
			self.UserLoginEvent.trigger(res);
		});
		webApiService.on('UserService', 'UserLogout', (res: WsResponseModelT<Boolean>) => {
			self.UserLogoutEvent.trigger(res);
		});
		webApiService.on('UserService', 'InviteUser', (res: WsResponseModelT<Boolean>) => {
			self.InviteUserEvent.trigger(res);
		});
		webApiService.on('UserService', 'AcceptInvation', (res: WsResponseModelT<Boolean>) => {
			self.AcceptInvationEvent.trigger(res);
		});
		webApiService.on('UserService', 'GetUsers', (res: WsResponseModelT<SearchUsersResponse>) => {
			self.GetUsersEvent.trigger(res);
		});
		webApiService.on('UserService', 'BlockUser', (res: WsResponseModelT<Boolean>) => {
			self.BlockUserEvent.trigger(res);
		});
		webApiService.on('UserService', 'ChangePassword', (res: WsResponseModelT<Boolean>) => {
			self.ChangePasswordEvent.trigger(res);
		});
		webApiService.on('UserService', 'ResetPasswordGetToken', (res: WsResponseModelT<Boolean>) => {
			self.ResetPasswordGetTokenEvent.trigger(res);
		});
		webApiService.on('UserService', 'ResetPassword', (res: WsResponseModelT<Boolean>) => {
			self.ResetPasswordEvent.trigger(res);
		});
		webApiService.on('UserService', 'GetCurrentUser', (res: WsResponseModelT<UserIdentity>) => {
			self.GetCurrentUserEvent.trigger(res);
		});
	}
	UserLogin(request: RequestLogin = null) { 	
        this.webApiService.send('UserService', 'UserLogin' , request);
    }
	UserLogout() { 	
        this.webApiService.send('UserService', 'UserLogout' );
    }
	InviteUser(request: InviteUserRequest = null) { 	
        this.webApiService.send('UserService', 'InviteUser' , request);
    }
	AcceptInvation(request: ActivateUserRequest = null) { 	
        this.webApiService.send('UserService', 'AcceptInvation' , request);
    }
	GetUsers(request: GetUsersRequest = null) { 	
        this.webApiService.send('UserService', 'GetUsers' , request);
    }
	BlockUser(id: number = 0) { 	
        this.webApiService.send('UserService', 'BlockUser' , id);
    }
	ChangePassword(request: ChangePasswordUserRequest = null) { 	
        this.webApiService.send('UserService', 'ChangePassword' , request);
    }
	ResetPasswordGetToken(request: ResetPasswordGetTokenRequest = null) { 	
        this.webApiService.send('UserService', 'ResetPasswordGetToken' , request);
    }
	ResetPassword(request: ResetPasswordRequest = null) { 	
        this.webApiService.send('UserService', 'ResetPassword' , request);
    }
	GetCurrentUser() { 	
        this.webApiService.send('UserService', 'GetCurrentUser' );
    }
	 // async methods
	async userLoginAsync(request: RequestLogin = null): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.UserLogin.bind(this), this.UserLoginEvent)(request);
	}
	async userLogoutAsync(): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.UserLogout.bind(this), this.UserLogoutEvent)();
	}
	async inviteUserAsync(request: InviteUserRequest = null): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.InviteUser.bind(this), this.InviteUserEvent)(request);
	}
	async acceptInvationAsync(request: ActivateUserRequest = null): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.AcceptInvation.bind(this), this.AcceptInvationEvent)(request);
	}
	async getUsersAsync(request: GetUsersRequest = null): Promise<SearchUsersResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetUsers.bind(this), this.GetUsersEvent)(request);
	}
	async blockUserAsync(id: number = 0): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.BlockUser.bind(this), this.BlockUserEvent)(id);
	}
	async changePasswordAsync(request: ChangePasswordUserRequest = null): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.ChangePassword.bind(this), this.ChangePasswordEvent)(request);
	}
	async resetPasswordGetTokenAsync(request: ResetPasswordGetTokenRequest = null): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.ResetPasswordGetToken.bind(this), this.ResetPasswordGetTokenEvent)(request);
	}
	async resetPasswordAsync(request: ResetPasswordRequest = null): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.ResetPassword.bind(this), this.ResetPasswordEvent)(request);
	}
	async getCurrentUserAsync(): Promise<UserIdentity> {
		return EventToAsyncDecorator.getInstance().bind(this.GetCurrentUser.bind(this), this.GetCurrentUserEvent)();
	}
}