import logLevel from "./filterLogLevel"
import logType from "./filterLogType"
import hardwareLogType from "./filterHardwareLogType"
import dateFormat from "./filterDateFormat"
import intervalMs from "./filterIntervalMs"

class Filters{    
    LogLevel: (value:number)=> string
    LogType: (value:number)=> string
    HardwareLogType: (value:number)=> string       
    DateFormat: (value:Date, format?:string)=> string       
    IntervalMs: (start:Date, end?:Date)=> string       
}

export default function registerFilters(app){
    let filters :Filters = {
        LogLevel : logLevel,
        LogType : logType,
        HardwareLogType: hardwareLogType,
        DateFormat:dateFormat,
        IntervalMs: intervalMs
    };
    app.config.globalProperties.$filters = filters;
    //app.config.globalProperties.console = console;
}

declare module "@vue/runtime-core" {
    //Bind to `this` keyword
    interface ComponentCustomProperties {
        $filters: Filters;
        //console: Console;
    }
  }