<template>
  <div class="sidebar" :class="[{ minimized: hideMenu }, { min: min }]">
    <div class="logo">
      <div @click="goHome()">
        <div class="logo-block">
          <img alt="" src="@assets/img/logo.png" />
        </div>
      </div>
      <div @click="goHome" class="log-text">LOGGER</div>
    </div>
    <nav>
      <!-- -------------------------------МЕНЮ---------------------------------------------- -->
      <!-- ------------------------------ Текущие показатели----------------------------------------------------- -->
      <router-link
        @click="showSubMenu"
        class="menu-item"
        :exact-active-class="'active'"
        :to="{ name: 'current-indicator' }"
      >
        <i class="icon-moon icon-speed"></i>Текущие показатели
      </router-link>

      <!-- ------------------------------ Нагрузка на процессор----------------------------------------------------- -->
      <router-link
        @click="showSubMenu"
        class="menu-item"
        :exact-active-class="'active'"
        :to="{ name: 'cpu-load' }"
      >
        <i class="icon-moon icon-cpu"></i> Нагрузка на процессор
      </router-link>

      <!-- ------------------------------ Логи методов----------------------------------------------------- -->
      <div
        class="menu-item sub"
        :class="{
          'active-sub-menu': isActiveMenuMetodLogs,
        }"
      >
        <div @click="activeClassMetodLogs">
          <i class="icon-moon icon-list"></i>Логи методов
        </div>

        <div class="sub-menu mt-3">
          <router-link
            class="menu-item"
            :exact-active-class="'active'"
            :to="{ name: 'metod-list' }"
          >
            Список методов
          </router-link>

          <router-link
            class="menu-item"
            :exact-active-class="'active'"
            :to="{ name: 'average-response-time-of-methods' }"
          >
            Среднее время откл. методов
          </router-link>

          <router-link
            class="menu-item"
            :exact-active-class="'active'"
            :to="{ name: 'number-of-open-and-closed-sessions' }"
          >
            Кол-во откр. и закр. сессий
          </router-link>

          <router-link
            class="menu-item"
            :exact-active-class="'active'"
            :to="{ name: 'number-active-users' }"
          >
            Кол-во активных пользователей
          </router-link>

          <router-link
            class="menu-item"
            :exact-active-class="'active'"
            :to="{ name: 'logs-view' }"
          >
            Просмотр логов
          </router-link>

          <router-link
            class="menu-item"
            :exact-active-class="'active'"
            :to="{ name: 'active-logs-view' }"
          >
            Активные методы
          </router-link>

          <router-link
            class="menu-item"
            :exact-active-class="'active'"
            :to="{ name: 'top-height-methods-load' }"
          >
            Топ нагруженных методов
          </router-link>

          <router-link
            class="menu-item"
            :exact-active-class="'active'"
            :to="{ name: 'logs-statistics' }"
          >
            Статистика
          </router-link>

          <router-link
            class="menu-item"
            :exact-active-class="'active'"
            :to="{ name: 'logs-view-errors' }"
          >
            Ошибки
          </router-link>
        </div>
      </div>

      <!-- ------------------------------ Логи контента----------------------------------------------------- -->
      <div
        :class="{
          'active-sub-menu': isActiveMenuContentMetod,
        }"
        class="menu-item sub"
      >
        <div @click="activeClassContentLogs">
          <i class="icon-moon icon-list2"></i> Логи контента
        </div>
        <div class="sub-menu mt-3">
          <router-link
            class="menu-item"
            :exact-active-class="'active'"
            :to="{ name: 'content-logs-view' }"
          >
            Просмотр логов
          </router-link>

          <router-link
            class="menu-item"
            :exact-active-class="'active'"
            :to="{ name: 'content-logs-view-active' }"
          >
            Активные логи
          </router-link>

          <router-link
            class="menu-item"
            :exact-active-class="'active'"
            :to="{ name: 'content-logs-view-errors' }"
          >
            Ошибки
          </router-link>
        </div>
      </div>

      <!-- ------------------------------Администрирование----------------------------------------------------- -->
      <router-link
        @click="showSubMenu"
        class="menu-item"
        :exact-active-class="'active'"
        :to="{ name: 'administration' }"
      >
        <i class="icon-moon icon-admin-with-cogwheels"></i>Администрирование
      </router-link>

      <!-- ------------------------------ Мой профиль----------------------------------------------------- -->
      <router-link
        @click="showSubMenu"
        class="menu-item"
        :exact-active-class="'active'"
        :to="{ name: 'my-profile' }"
      >
        <i class="icon-moon icon-admin"></i>Мой профиль
      </router-link>

      <!-- ------------------------------ Список пользователей----------------------------------------------------- -->
      <router-link
        @click="showSubMenu"
        class="menu-item"
        :exact-active-class="'active'"
        :to="{ name: 'user-list' }"
      >
        <i class="icon-moon icon-users"></i>Список пользователей
      </router-link>

      <!-- <router-link class="menu-item" :to="{ name: 'chart-statistic-request' }">
        <i class="icon icon-apteka-group"></i> Статистика запросов
      </router-link>

      <router-link class="menu-item" :to="{ name: 'chart-statistic-logs' }">
        <i class="icon icon-apteka-group"></i> Статистика логов
      </router-link> -->

      <!-- Активные методы -->
      <!-- <div class="menu-item sub">
        <div @click="showSubMenu('active-logs-view')">
          <i class="icon icon-apteka-to-do-list"></i> Активные методы
          
        </div>
        <div
          class="sub-menu mt-2"
          v-show="
            currentSubMenu === 'active-logs-view' &&
            (currentRoute === 'active-logs-view' ||
              currentRoute === 'active-logs-view-errors')"
          >
          <router-link
            class="menu-item"
            :exact-active-class="'active'"
            :to="{ name: 'active-logs-view' }"
          >
            <i class="icon icon-apteka-group"></i>Просмотр логов
          </router-link>

          <router-link
            class="menu-item"
            :exact-active-class="'active'"
            :to="{ name: 'active-logs-view-errors' }"
          >
            <i class="icon icon-apteka-to-do-list"></i> Ошибки
          </router-link>
        </div>
      </div> -->

      <!-- Hardware logs -->
      <!-- <router-link
        class="menu-item"
        :exact-active-class="'active'"
        :to="{ name: 'hardware-logs' }"
      >
        <i class="icon icon-apteka-group"></i> Мониторинг ресурсов
      </router-link> -->

      <!-- <router-link
        class="menu-item"
        :exact-active-class="'active'"
        :to="{ name: 'change-password' }"
      >
        <i class="icon icon-key"></i> Сменить пароль
      </router-link> -->
    </nav>
    <!-- discount-cards
   slides
   articles
     promotions
       -->
  </div>
</template>
<script lang="ts">
import { Options, Prop, Vue } from "vue-property-decorator";
import { inject, provide, reactive } from "vue";
@Options({
  name: "LayoutSidebar",
})
export default class LayoutSidebar extends Vue {
  @Prop() hideMenu: boolean;

  minimized: boolean = false;
  min: boolean = false;
  activeArt = false;
  currentRoute = "";
  currentSubMenu = "";

  isActiveMenuMetodLogs = false;
  isActiveMenuContentMetod = false;

  activeClassMetodLogs() {
    this.isActiveMenuMetodLogs = !this.isActiveMenuMetodLogs;
  }

  activeClassContentLogs() {
    this.isActiveMenuContentMetod = !this.isActiveMenuContentMetod;
  }

  mounted() {
    this.currentRoute = this.$route.name.toString();
    this.initMenu();

    const startWindowWidth = window.innerWidth;
    this.stateNavPanel(startWindowWidth);

    window.onresize = (event) => {
      const windowWidth = event.target.innerWidth;
      this.stateNavPanel(windowWidth);
    };

    // console.log(`currentRoute`, this.currentRoute);
  }
  beforeUpdate() {
    console.log("updated");
    // this.currentRoute = this.$route.name.toString();
    // this.initMenu();
  }
  updated() {
    // console.log("updated");
  }
  stateNavPanel(currentWindowWidth: number) {
    if (this.hideMenu) {
      return;
    }
    this.$emit("update:hideMenu", currentWindowWidth < 1000);
  }

  goHome() {
    this.$router.push({ path: "/" });
  }

  // showSubMenu(value: string = null) {
  //   this.activeClassContentLogs();
  //   this.activeClassMetodLogs();
  //   // this.currentRoute = value;
  //   // if (value) {
  //   //   this.$router.push({ name: value });
  //   // }
  //   this.initMenu();
  // }
  initMenu() {
    this.currentSubMenu = "";
    if (
      this.currentRoute === "metod-list" ||
      this.currentRoute === "top-height-methods-load" ||
      this.currentRoute === "logs-statistics" ||
      this.currentRoute === "logs-view-errors"
    ) {
      this.currentSubMenu = "metod-list";
    } else {
      this.currentSubMenu = "";
    }

    if (
      this.currentRoute === "content-logs-view" ||
      this.currentRoute === "content-logs-view-errors"
    ) {
      this.currentSubMenu = "content-logs-view";
    }

    if (
      this.currentRoute === "active-logs-view" ||
      this.currentRoute === "active-logs-view-errors"
    ) {
      this.currentSubMenu = "active-logs-view";
    }
  }
}
</script>

<style lang="less" scoped>
.sidebar {
  background: rgb(50, 39, 87);
  max-width: 315px;
  width: 100%;
  // transition: width 0.3s linear;
  .logo {
    height: 74px;
    background: rgb(34, 26, 58);
    border-right: 1px solid #176363;
    display: flex;
    align-items: center;
    padding-left: 10px;
    .logo-block {
      width: 35px;
      img {
        width: 100%;
      }
    }
    cursor: pointer;
    .log-text {
      color: #fff;
      min-width: 229px;
      text-transform: uppercase;
      margin-left: 10px;
      overflow: hidden;
    }
    .logo-btn {
      margin-left: auto;
      cursor: pointer;
      margin-right: 20px;
    }
  }
  &.minimized {
    width: 0%;
    min-width: 0px;
    overflow: hidden;
    .logo {
    }
    .sub-menu {
      transition: all 0.5s;
      margin-left: 0;
    }
  }
  &.min {
    width: 0px;
    min-width: 0px;
    overflow: hidden;
  }
  .sub-menu {
    padding-left: 2.5%;
    border-left: 1px solid #403d6a;
    margin-left: 3%;
    transition: all 0.5s;
    // margin-left: 2rem;
    .menu-item {
      padding-top: 10px;
      &.active,
      &:hover {
        border-right: 3px solid rgb(99, 1, 87);
      }
    }
  }

  nav {
    margin-top: 24px;
    .menu-item {
      cursor: pointer;
      min-width: 285px;
      display: flex;
      align-items: center;
      padding: 10px 20px;
      min-height: 41px;
      color: #fff;
      transition: color 0.05s ease, background-color 0.15s ease,
        border-color 0.15s ease, box-shadow 0.15s ease, max-height 1s ease;
      .icon-moon {
        margin-right: 20px;
      }
      &:focus {
        outline: unset;
      }
      text-decoration: none;
      &:hover {
        background-color: rgb(64, 49, 112);
        transition: color 0.15s ease, background-color 0.15s ease,
          border-color 0.15s ease, box-shadow 0.15s ease;
      }
      &.active {
        background: #221a3a;
        color: #fc2586;
        transition: all 0.3s linear;
        i:before {
          color: #fc2586;
        }
      }
    }
    .sub {
      max-height: 40px;
      overflow: hidden;
      display: block;
      border-right: none;
      transition: max-height 1s linear;
      .menu-item {
        transition: background-color 0.15s ease, color 0.05s ease;
        &:hover {
        }
      }
    }
    .active-sub-menu {
      color: #f32373;
      max-height: 6000px;
      // transition: color 0.05s ease, max-height 1s linear;
      .icon-moon:before {
        color: #f32373;
        transition: color 0.05s ease;
      }
      .menu-item {
        transition: background-color 0.15s ease;
        &:hover {
        }
      }
      .router-link-active {
        background-color: #221a3a;
      }
      &:hover {
        background-color: inherit;
      }
    }
  }
}
</style>