import { WebApiService } from './plugins/webApiService';
import EndpointService from './LoggerMicroService/Services/Ws/EndpointService';
import EndpointsStatisticsService from './LoggerMicroService/Services/Ws/EndpointsStatisticsService';
import IndicatorsService from './LoggerMicroService/Services/Ws/IndicatorsService';
import LogGetterService from './LoggerMicroService/Services/Ws/LogGetterService';
import LogLevelService from './LoggerMicroService/Services/Ws/LogLevelService';
import LogTypeService from './LoggerMicroService/Services/Ws/LogTypeService';
import ProjectLogGroupService from './LoggerMicroService/Services/Ws/ProjectLogGroupService';
import TopEndpointLogsService from './LoggerMicroService/Services/Ws/TopEndpointLogsService';
import WsCallbacks from './WebSocketControllers/Core/WsCallbacks';
import WsStateWsController from './WebSocketControllers/Core/WsStateWsController';
import AnalyticEndpointsService from './AnalyticEndpoints/Services/AnalyticEndpointsService';
import UserService from './Users/Services/UserService';
import AnalyticLogService from './AnalyticLogs/Services/AnalyticLogService';
import LoggerDictionaryService from './LoggerDictionary/Services/LoggerDictionaryService';
import AnalyticUsersService from './AnalyticUsers/Service/AnalyticUsersService'

export default class apiDataSource {
	webApiService: WebApiService;
	EndpointService: EndpointService;
	EndpointsStatisticsService: EndpointsStatisticsService;
	IndicatorsService: IndicatorsService;
	LogGetterService: LogGetterService;
	LogLevelService: LogLevelService;
	LogTypeService: LogTypeService;
	ProjectLogGroupService: ProjectLogGroupService;
	TopEndpointLogsService: TopEndpointLogsService;
	WsCallbacks: WsCallbacks;
	WsStateWsController: WsStateWsController;
	AnalyticEndpointsService: AnalyticEndpointsService;
	UserService: UserService;
	AnalyticLogService: AnalyticLogService;
	LoggerDictionaryService: LoggerDictionaryService;
	AnalyticUsersService: AnalyticUsersService;
	constructor(webApiUrl: string, cookieName: string = "Auth-Token") {
		this.webApiService = new WebApiService(webApiUrl,cookieName);
		this.EndpointService = new EndpointService(this.webApiService);
		this.EndpointsStatisticsService = new EndpointsStatisticsService(this.webApiService);
		this.IndicatorsService = new IndicatorsService(this.webApiService);
		this.LogGetterService = new LogGetterService(this.webApiService);
		this.LogLevelService = new LogLevelService(this.webApiService);
		this.LogTypeService = new LogTypeService(this.webApiService);
		this.ProjectLogGroupService = new ProjectLogGroupService(this.webApiService);
		this.TopEndpointLogsService = new TopEndpointLogsService(this.webApiService);
		this.WsCallbacks = new WsCallbacks(this.webApiService);
		this.WsStateWsController = new WsStateWsController(this.webApiService);
		this.AnalyticEndpointsService = new AnalyticEndpointsService(this.webApiService);
		this.UserService = new UserService(this.webApiService);
		this.AnalyticLogService = new AnalyticLogService(this.webApiService);
		this.LoggerDictionaryService = new LoggerDictionaryService(this.webApiService);
		this.AnalyticUsersService = new AnalyticUsersService(this.webApiService);
	}

}
