
export default function IntervalMs(dateStart: Date, dateEnd?: Date): string {
  let end  = dateEnd? new Date(dateEnd): new Date();
  let interval = end.getTime() - new Date(dateStart).getTime();
  if( interval< 10 * 1000)
    return interval + "ms";
  if(interval< 60 *1000)
    return (Math.ceil(interval/1000 *100)/100) + "s";
  if(interval<60 * 60 * 1000)
    return (Math.ceil(interval/1000/60*100)/100) + "m";
  
  return (Math.ceil(interval/1000/60/60*100)/100) + "h";
}