import { WebApiService } from '../../../plugins/webApiService'
import { Events } from '../../../plugins/events'
import EventToAsyncDecorator from '../../../plugins/eventToAsyncDecorator'
import GetLogRequest from '../../../LoggerMicroService/Services/Models/Request/LogGetterService/GetLogRequest';
import GetEndpointLogsRequest from '../../../LoggerMicroService/Services/Models/Request/LogGetterService/GetEndpointLogsRequest';
import GetHardwareLogsRequest from '../../../LoggerMicroService/Services/Models/Request/LogGetterService/GetHardwareLogsRequest';
import WsResponseModelT from '../../../SchemaBuilderServer/Models/WsResponseModelT';
import GetLogsResponse from '../../../LoggerMicroService/Services/Models/Response/LogGetterService/GetLogsResponse';
import GetLogDataResponse from '../../../LoggerMicroService/Services/Models/Response/LogGetterService/GetLogDataResponse';
import GetEndpointLogsResponse from '../../../LoggerMicroService/Services/Models/Response/LogGetterService/GetEndpointLogsResponse';
import GetEndpointLogDataResponse from '../../../LoggerMicroService/Services/Models/Response/LogGetterService/GetEndpointLogDataResponse';
import GetHardwareLogsResponse from '../../../LoggerMicroService/Services/Models/Response/LogGetterService/GetHardwareLogsResponse';
import GetLogDetailsResponse from '../../../LoggerMicroService/Services/Models/Response/LogGetterService/GetLogDetailsResponse';
import GetEndpointLogDetailsResponse from '../../../LoggerMicroService/Services/Models/Response/LogGetterService/GetEndpointLogDetailsResponse';

export default class LogGetterService {
	webApiService: WebApiService;
	GetLogsEvent = new Events<WsResponseModelT<GetLogsResponse>>();
	GetLogDataEvent = new Events<WsResponseModelT<GetLogDataResponse>>();
	GetEndpointLogsEvent = new Events<WsResponseModelT<GetEndpointLogsResponse>>();
	GetEndpointLogDataEvent = new Events<WsResponseModelT<GetEndpointLogDataResponse>>();
	GetHardwareLogsEvent = new Events<WsResponseModelT<GetHardwareLogsResponse>>();
	GetLogDetailsEvent = new Events<WsResponseModelT<GetLogDetailsResponse>>();
	GetEndpointLogDetailsEvent = new Events<WsResponseModelT<GetEndpointLogDetailsResponse>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('LogGetterService', 'GetLogs', (res: WsResponseModelT<GetLogsResponse>) => {
			self.GetLogsEvent.trigger(res);
		});
		webApiService.on('LogGetterService', 'GetLogData', (res: WsResponseModelT<GetLogDataResponse>) => {
			self.GetLogDataEvent.trigger(res);
		});
		webApiService.on('LogGetterService', 'GetEndpointLogs', (res: WsResponseModelT<GetEndpointLogsResponse>) => {
			self.GetEndpointLogsEvent.trigger(res);
		});
		webApiService.on('LogGetterService', 'GetEndpointLogData', (res: WsResponseModelT<GetEndpointLogDataResponse>) => {
			self.GetEndpointLogDataEvent.trigger(res);
		});
		webApiService.on('LogGetterService', 'GetHardwareLogs', (res: WsResponseModelT<GetHardwareLogsResponse>) => {
			self.GetHardwareLogsEvent.trigger(res);
		});
		webApiService.on('LogGetterService', 'GetLogDetails', (res: WsResponseModelT<GetLogDetailsResponse>) => {
			self.GetLogDetailsEvent.trigger(res);
		});
		webApiService.on('LogGetterService', 'GetEndpointLogDetails', (res: WsResponseModelT<GetEndpointLogDetailsResponse>) => {
			self.GetEndpointLogDetailsEvent.trigger(res);
		});
	}
	GetLogs(request: GetLogRequest = null) { 	
        this.webApiService.send('LogGetterService', 'GetLogs' , request);
    }
	GetLogData(requestId: string = '') { 	
        this.webApiService.send('LogGetterService', 'GetLogData' , requestId);
    }
	GetEndpointLogs(request: GetEndpointLogsRequest = null) { 	
        this.webApiService.send('LogGetterService', 'GetEndpointLogs' , request);
    }
	GetEndpointLogData(requestId: string = '') { 	
        this.webApiService.send('LogGetterService', 'GetEndpointLogData' , requestId);
    }
	GetHardwareLogs(request: GetHardwareLogsRequest = null) { 	
        this.webApiService.send('LogGetterService', 'GetHardwareLogs' , request);
    }
	GetLogDetails(requestId: string = '') { 	
        this.webApiService.send('LogGetterService', 'GetLogDetails' , requestId);
    }
	GetEndpointLogDetails(requestId: string = '') { 	
        this.webApiService.send('LogGetterService', 'GetEndpointLogDetails' , requestId);
    }
	 // async methods
	async getLogsAsync(request: GetLogRequest = null): Promise<GetLogsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetLogs.bind(this), this.GetLogsEvent)(request);
	}
	async getLogDataAsync(requestId: string = ''): Promise<GetLogDataResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetLogData.bind(this), this.GetLogDataEvent)(requestId);
	}
	async getEndpointLogsAsync(request: GetEndpointLogsRequest = null): Promise<GetEndpointLogsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetEndpointLogs.bind(this), this.GetEndpointLogsEvent)(request);
	}
	async getEndpointLogDataAsync(requestId: string = ''): Promise<GetEndpointLogDataResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetEndpointLogData.bind(this), this.GetEndpointLogDataEvent)(requestId);
	}
	async getHardwareLogsAsync(request: GetHardwareLogsRequest = null): Promise<GetHardwareLogsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetHardwareLogs.bind(this), this.GetHardwareLogsEvent)(request);
	}
	async getLogDetailsAsync(requestId: string = ''): Promise<GetLogDetailsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetLogDetails.bind(this), this.GetLogDetailsEvent)(requestId);
	}
	async getEndpointLogDetailsAsync(requestId: string = ''): Promise<GetEndpointLogDetailsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetEndpointLogDetails.bind(this), this.GetEndpointLogDetailsEvent)(requestId);
	}
}